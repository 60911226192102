<template>
  <div class='container'>
    <h1 style="text-align: center;font-size: 4.8vw;">心理普查结果反馈书</h1>
    <div class="content">
      <div style="text-align: right;">
        <!-- <button class="btn">下载报告</button> -->
      </div>
      <div class="text-box">
      <div><span class="filling" style="padding: 0 4vw;">{{ feedback.teacher }}</span>班主任：</div>
      <div style="text-indent:2em;line-height: 2em;margin-top: 2.6667vw;">
        <span>我校心理辅导站于</span>
        <span class="filling" style="width: 26.6667vw;">{{ startTime[0] }}</span>
        <span>年</span>
        <span class="filling" style="width: 16vw;">{{ startTime[1] }}</span>
        <span>月</span>
        <span class="filling" style="width: 16vw;">{{ startTime[2] }}</span>
        <span>日 — </span>
        <span class="filling" style="width: 16vw;">{{ endTime[1] }}</span>
        <span>月</span>
        <span class="filling" style="width: 13.3333vw;">{{ endTime[2] }}</span>
        <span>日对我校</span>
        <span class="filling" style="width: 34.6667vw;">{{ feedback.teacher&&feedback.teacher.split('级')[0] }}</span>
        <span>级学生开展了心理健康普查工作，现就普查情况汇报如下。</span>
      </div>
      <!-- <div style="min-height: 13.3333vw;">
              <div class="text-item" style="font-weight: bold;">【测验介绍】</div>
              <div class="text-item" style="margin-top: 2.6667vw;">1.中学生心理健康综合测量(MHT)</div>
              <div class="text-item">
                <span>该量表包含学习焦虑、对人焦虑、孤独倾向、自责倾向、过敏</span>
                <span>倾向、身体症状、恐怖倾向、冲动倾向8个方面，100个测验题目。</span>
                <span>该量表可以帮助学生更深人地了解自己，帮助自己保持良好心态。</span>
                <span>该量表分如果超过65分，则表明被试可能存在一定的心理困扰，</span>
                <span>需要身边人给予更多的理解与支持。</span>
              </div>
              <div class="text-item" style="margin-top: 5.3333vw;">2.90项症状自评量表(SCL-90)</div>
              <div class="text-item">
                <span>该测验的目的是从感觉，情感、思维、意识、行为直到生活习</span>
                <span>惯、人际关系、饮食睡眠等多种角度，评定一个人是否有某种心理</span>
                <span>症状及其严重程度如何。如果总分超过160分则表明该被试可能存</span>
                <span>在一定的心理障碍。该量表仅仅根据最近一周的感觉，其结果只表</span>
                <span>明短期内的心理健康状态，如果能给予重视和正向关注，则相对容易进行调整。</span>
              </div>
            </div> -->
      </div>
      <div class="text-box">
            <div class="text-item" style="font-weight: bold;">【测验结果】</div>
            <div class="text-item">
              <span>你班共有</span>
              <span class="filling" style="width: 16vw;">{{ feedback.testedNum }}</span>
              <span>名学生参加测试，有效测试为</span>
              <span class="filling" style="width: 16vw;">{{ feedback.validityNum||0 }}</span>
              <span>份，其中需要进行心理预警的同学有</span>
              <span class="filling" style="width: 16vw;">{{ feedback.warningNum||0 }}</span>
              <span>名，具体情况如下.</span>
            </div>
            <div style="margin: 2.6667vw 0 8vw;">
              <!-- biaoge -->
            </div>
            <div class="table">
              <div class="table-title">学生心理预警预备名单</div>
              <colgroup>
                <col style="width: 14.4vw;">
                <col style="flex: 1;padding: 0 2.6667vw;">
                <col style="width: 16vw;">
                <col style="width: 16vw;">
                <col style="flex: 2;padding: 0 2.6667vw;">
              </colgroup>
              <table>
                <thead>
                  <tr>
                    <th style="width: 13%;">序号</th>
                    <th style="width: 19%;padding: 1.3333vw 1.3333vw;word-break: break-all">学号</th>
                    <th style="width: 20%;">姓名</th>
                    <th style="width: 13%">性别</th>
                    <th style="width: 35%;padding: 1.3333vw 1.3333vw;">MHT总分 ≥ 65  MTH个别因子 ≤ 2</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item,index) in tableData" :key="item.id">
                    <td style="width: 13%;">{{index+1}}</td>
                    <td style="width: 19%;padding: 1.3333vw 1.3333vw;word-break: break-all">{{item.sn}}</td>
                    <td style="width: 20%;">{{item.trueName}}</td>
                    <td style="width: 13%">{{item.gender}}</td>
                    <td style="width: 35%;padding: 1.3333vw 1.3333vw;">{{item.MHT_sumScore}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="text-item" style="font-weight: bold;">【处理建议】</div>
            <div class="text-item">
              1.请班主任对这些心理预警预备名单中的学生多加积极关注和肯定鼓励。
            </div>
            <div class="text-item">
              2.结合学生平时表现，将需要重点关注对象的基本情况填人学生心理危机预警登记表中，并及时上报给学校心理辅导中心。
            </div>
            <div class="text-item">
              3.个别学生如有需要，可推荐其做心理辅导。
            </div>
            <div class="text-item">
              4.本次测验结果仅供参考，不能作为心理问题诊断的依据，请班主任注意对学生保密。
            </div>
          </div>
    </div>
  </div>
</template>

<script>
import {classStatisticsReport} from '@/api/project.js'
import {projectDetail} from '@/api/exam.js'

export default {
  data(){
    return{
      feedback: {},
      startTime: [],
      endTime: [],
      tableData: []
    }
  },
  created(){
    this.classStatisticsReport()
  },
  methods:{
    // 获取报告
    async classStatisticsReport() {
      const {studyClass,grade,id}=this.$route.query
      const params={
        examId:id,
        grade,
        studyClass
      }
      const { data } = await classStatisticsReport(params)
      this.feedback = data
      this.tableData = data.warningList
      this.startTime = data.startTime && data.startTime.split('-')
      this.endTime = data.endTime && data.endTime.split('-')
      // this.getProjectDetail(data.projectId)
    },
    // 获取量表详情
    async getProjectDetail(projectId) {
      const res = await projectDetail({projectId: 24})
      console.log(res)
    },
  }
}
</script>
<style lang='less' scoped>
.container{
  padding: 4vw 0 4vw 0;
  min-height: 100%;
  .content{
    padding: 0 2.6667vw ;
    .btn{
      // width: 16vw;
      margin-top: 2.6667vw;
      padding: 0 2.6667vw;
      height: 6.9333vw;
      color: #fff;
      background: #5EDBC9;
      border-radius: .8vw .8vw .8vw .8vw;
    }
    .text-box{
      font-size: 4vw;
      margin-top: 5.3333vw;
      border: .5333vw solid #000;
      padding: 4vw 2.6667vw;
      .table{
        margin-bottom: 4vw;
        .table-title{
          text-align: center;
          color: #909399;
          font-size: 4vw;
          font-weight: 550;
          margin-bottom: 4vw;
        }
        table{
          width: 100%;
          border: .2667vw solid #ebeef5;
          border-collapse: collapse;
          table-layout: fixed;
          tr{
              display: flex;
              width: 100%;
            }
          thead{
            color: #909399;
            font-size: 3.4667vw;
          }
          th,td{
            // border: .2667vw solid #ebeef5;
            border-collapse: collapse;
            vertical-align: top;
            text-align: center;
          }
          th{
            display: flex;
            justify-content: center;
            align-items: center;
            border-right: .2667vw solid #ebeef5;
            &:last-child{
              border-right:  none;
            }
          }
          td{
            font-size: 2.9333vw;
            color: #606266;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-content: center;
            border: .2667vw solid #ebeef5;
            border-bottom: none;
            border-left: none ;
          }
          tbody{
            tr{
              :last-child{
                border-right: none;
              }
            }
          }
        }
      }
      span{
        line-height: 8vw;
      }
      .text-item{
        text-indent:2em;
        line-height: 2em;
        letter-spacing:.5333vw;
      }
    }
  }
  .filling{
    display: inline-block;
    border-bottom: .2667vw solid #62666c;
    text-align: center;
    text-indent:0em;
  }
}
</style>