import {request} from '@/utils/request'
// 量表分类
export function cats (params) {
    return request({
        url: '/h5/project/cats',
        method: 'get',
        params
    })
}


// 量表列表
export function list (params) {
    return request({
        url: '/h5/project/list',
        method: 'get',
        params
    })
}

// 随机查询量表 
export function getProjectRadom (params) {
    return request({
        url: '/h5/project/listByLimit',
        method: 'get',
        params:{
            count:params.count,
            type:params.type||0
        }
    })
}

//量表详情
export function detail (projectId) {
    return request({
        url: '/h5/project/detail',
        method: 'get',
        params: {
            projectId
        }
    })
}

//多个量表详情
export function details (projectId) {
    return request({
        url: '/h5/project/details',
        method: 'get',
        params: {
            projectId
        }
    })
}

//题目列表
export function questions (params) {
    return request({
        url: '/h5/question/list',
        method: 'get',
        params
    })
}


//答题
export function answerQuestion (data) {
    return request({
        url: '/h5/question/answer',
        method: 'post',
        data
    })
}

//banner
export function bannerList (params) {
    return request({
        url: '/h5/banner/list',
        method: 'get',
        params
    })
}

//推荐
export function recommend () {
    return request({
        url: '/project/recommend',
        method: 'get'
    })
}

//猜一猜
export function guess (mode) {
    return request({
        url: '/project/guess',
        method: 'get',
        params: {
            mode
        }
    })
}

//每日一测
export function dailyTest () {
    return request({
        url: '/project/dailyTest',
        method: 'get'
    })
}

// 预约报告发送预约请求
export function reportUnscramble (data) {
    return request({
        url: '/h5/doctor/booking',
        method: 'post',
        data
    })
}

// ----------------------------企微接口 青少年学校段接口--------------------------------------3
// 获取部门列表
export function getDepartments () {
    return request({
        url: '/orgWecom/departments',
        method: 'get'
    })
}
// 带分类的量表列表
export function projectsV2 (params) {
    return request({
        url: '/orgExam/projectsV2',
        method: 'get',
        params
    })
}

// 发布测评(企业微信)
export function wxworkExamAdd(data) {
    return request({
      // url: '/orgExam/add',
      url: '/orgExam/addDepartment',
      method: 'post',
      data
    })
  }

  // 场次详情
export function examDetail (params) {
    return request({
        url: '/orgExam/detail',
        method: 'get',
        params
    })
}
// 班级报告列表
export function classStatistics (params) {
    return request({
        url: '/orgExam/classStatistics',
        params
    })
}

// 查看报告
export function classStatisticsReport (params) {
    return request({
        url: '/orgExam/classStatisticsReport',
        params
    })
}